<template>
  <div class="examination">
    <div class="part">
      <el-row :gutter="100" justify="space-around">
        <el-col :span="24">
          <div class="notice">
            <div class="notice_item">
              <div class="qrtip">
                <p class="txt">预约考试时间</p>
                <p>成功预约后我们将以短信的方式通知您准时参加考试</p>

                <div>
                  <el-button type="danger" class="exam" @click="appshow"
                    >预约考试>></el-button
                  >
                </div>
              </div>
              <div>
                <img src="../assets/images/exam/img5.png" alt srcset />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="24" v-for="(item, index) in examList" :key="index">
          <div class="entrance">
            <p class="title">
              <span v-if="index == 0">模块一:</span>
              <span v-if="index == 1">模块二:</span>
              <span v-if="index == 2">模块三:</span>
              {{ item.title }}
            </p>
            <p>正式考试说明：</p>
            <div
              v-html="item.content"
              style="color:#666;padding-top:2px;font-size:14px"
            ></div>
            <el-button
              type="danger"
              class="exam"
              @click="examing(item.paper_id, item.class, item.title)"
              >开始考试</el-button
            >
          </div>
        </el-col>
        <el-col :span="24">
          <div class="tip">
            <div class="content">
              <div class="tip-item">
                <h2>友情提示</h2>
                <p class="indent">
                  （1）考试前系统将对您的身份进行认证，信息一经认证后无法更改，考生的资料将对应证书的信息，请认真填写个人真实信息，以防出现身份错误造成您的损失；
                </p>
                <p class="indent">
                  （2）网培师智能监考平台在考试过程中会通过远程视频监控持续进行身份信息识别，请考生遵守考场纪律；
                </p>
                <p class="indent">
                  （3）考生考试期间请保持面部一直处于摄像头有效区域，如考试期间发现考生离开摄像头有效区域，则视为考生私自离开考场，本次考试无效；
                </p>
                <p class="indent">
                  （4）考生在参加评测期间应该遵守考试纪律，独立完成评测内容；考试时不得向他人交流，也不能通过搜索引擎、书本等工具查阅相关内容；
                </p>
                <p class="indent">
                  （5）违反考试规定者一经发现，则作废本次所有考试并视为本次所有考试无效。
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 支付弹框 -->
      <el-dialog
        title="支付"
        :visible.sync="dialogVisible"
        width="550px"
        v-loading="loading"
        :close-on-click-modal="false"
        center
        class="payStyle"
      >
        <div>
          <span>付费内容:</span>
          培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
        </div>
        <div v-if="payRadio !== '1'">
          <span>输入兑换码: </span>
          <el-input
            placeholder="请输入兑换码"
            class="redeem"
            v-model="redeemData"
          ></el-input>
          <p class="warnRed">*请注意区分大小写</p>
        </div>
        <div v-else><span>支付金额: </span>{{ this.$price }}</div>
        <div>
          <span>支付方式:</span
          ><el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
          <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
        </div>
        <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
          >立即支付</el-button
        >
        <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
      </el-dialog>
      <!-- 认证弹框 -->
      <el-dialog :visible.sync="showInfo" width="500px" v-loading="loading">
        <el-form
          ref="ruleForm"
          label-position="left"
          label-width="150px"
          :model="form"
          :rules="rules"
          hide-required-asterisk
        >
          <p>
            请确认您的身份信息，一旦认证成功将不能再次进行修改。如因身份信息有误导致无法考试、获取证书，本平台将不会退回费用。
          </p>
          <br />
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号：" prop="idcard">
            <el-input
              v-model="form.idcard"
              placeholder="请输入身份证号"
              @paste.native.capture.prevent="
                () => {
                  return false
                }
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="请再次确认身份证号:" prop="rIdcard">
            <el-input
              v-model="form.rIdcard"
              placeholder="请输入身份证号"
              @paste.native.capture.prevent="
                () => {
                  return false
                }
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="danger" size="small" @click="toRZ">认证</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 考试项选择弹框 -->
      <el-dialog
        title="试题选择"
        :visible.sync="examSelect"
        width="450px"
        v-loading="loading"
        center
      >
        <div class="examselect">
          <p>1.请选择一个您最擅长的视频编辑软件；</p>
          <p>2.您的选择将决定评测中视频剪辑部分试题，请酌情选择；</p>
          <p>3.<span>选择后将无法更改其他视频编辑软件，请慎重！</span></p>
          <el-radio-group v-model="radio" style="padding-left:10px;">
            <el-radio
              v-for="item in radioOptions"
              :key="item.id"
              :label="item.id"
              >{{ item.title }}</el-radio
            >
          </el-radio-group>
          <div class="text-center">
            <el-button type="primary" @click="isSelectSure">确定</el-button>
          </div>
        </div>
      </el-dialog>
      <!-- 预约弹窗 -->
      <el-dialog
        :visible.sync="attimeShow"
        width="400px"
        v-loading="loading"
        title="预约考试时间"
        class="dialog_yy"
      >
        <el-row>
          <el-col :span="12">
            <p>
              报考人：
              <span v-if="user">{{ user.name }}</span>
            </p>
          </el-col>
          <el-col :span="12">
            <p>
              手机号：
              <span v-if="user">{{ user.mobile }}</span>
            </p>
          </el-col>
          <!-- <el-col :span="24">
                        <p>身份证号：
                            <span></span>
                        </p>
                    </el-col> -->
          <el-col :span="24">
            <el-select v-model="attime" placeholder="请选择预约时间">
              <el-option
                v-for="item in appointmenttimes"
                :key="item.value"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="24">
            <el-button type="danger" class="exam" @click="appointment"
              >预约考试</el-button
            >
          </el-col>
        </el-row>
      </el-dialog>
      <!-- 认证提示 -->
      <el-dialog title="提示" :visible.sync="warningDialog" width="400px">
        <span>认证后将不能修改姓名和身份证号, 是否继续?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="warningDialog = false">取 消</el-button>
          <el-button type="primary" @click="warningToRZ">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 补考提示框 -->
      <!-- 考试未通过 -->
      <el-dialog title="温馨提示" :visible.sync="repairDialog" width="400px">
        <span>您是否使用补考机会（剩余1次）进行考试？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="repairDialog = false">取 消</el-button>
          <el-button type="primary" @click="isRepair">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 已通过 -->
      <el-dialog title="温馨提示" :visible.sync="examPassDialog" width="400px">
        <span>您已通过“{{ this.toForm.title }}”的考试！</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="examPassDialog = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 补考未通过 -->
      <el-dialog
        title="温馨提示"
        :visible.sync="examNotPassDialog"
        width="400px"
      >
        <span>您的补考机会已用尽，如果需要考试，请重新缴费！</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="examNotPassDialog = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import Sim from './Simulation'
let _this
export default {
  name: 'Examination1',
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入身份证号'))
      } else if (value !== this.form.idcard) {
        callback(new Error('两次输入不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        name: {
          required: true,
          message: '请输入姓名',
          trigger: 'blur',
        },

        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的身份证号',
            trigger: 'blur',
          },
        ],
        rIdcard: { required: true, validator: validatePass2, trigger: 'blur' },
      },
      payRadio: '1',
      toForm: {},
      cookieId: '',
      obj: {},
      form: {},
      redeemDialog: false,
      dialogVisible: false,
      warningDialog: false,
      showInfo: false,
      radioOptions: [],
      examSelect: false,
      loading: false,
      redeemData: '',
      order_number: '',
      radio: '',
      appointmenttimes: [],
      attime: '',
      attimeShow: false,
      isappointment: false,
      user: {},
      examList: [],
      token: '',
      redeem_number: '',
      isSelect: '',
      repairDialog: false,
      examPassDialog: false,
      examNotPassDialog: false,
      isrepair: '',
    }
  },
  created() {
    if (this.$store.state.wps_user.mobile) {
      _this = this
      this.form = {
        name: this.$store.state.wps_user.name,
        idcard: this.$store.state.wps_user.idcard,
      }
      this.obj = this.$store.state.wps_user
      _this.init()
      _this.getExam()
    } else {
      this.$message.warning('您还未登录，请先登录！')
      this.$router.push('Login')
    }
  },
  methods: {
    getExam() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'exam' + '/1')
        .then(data => {
          if (data.data.code == 200) {
            let res = data.data.data
            this.papersId = data.data.papers
            res.forEach(item => {
              if (item.type == 1) {
                this.examList.push(item)
              }
            })
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getRepair() {
      console.log(111)
      if (this.isrepair == 0) {
        this.getTest()
        console.log('hhhhh')
      } else if (this.isrepair == 1 || this.isrepair == 2) {
        this.examPassDialog = true
      } else if (this.isrepair == 3) {
        this.examNotPassDialog = true
      } else {
        this.repairDialog = true
      }
    },
    getTest() {
      if (!_this.obj.is_verfy) {
        //未认证
        _this.warningDialog = true
      } else {
        if (JSON.parse(this.toForm.istrue).length > 0) {
          _this.papers(this.toForm.istrue)
        } else {
          _this.setNetbanCookie(this.toForm.id)
        }
      }
    },
    isRepair() {
      this.setNetbanCookie(this.isrepair)
      this.repairDialog = false
    },
    init() {
      this.$http.get(process.env.VUE_APP_URL + 'apply').then(res => {
        if (res.data.code == 200) {
          this.appointmenttimes = res.data.data
        }
      })
      this.user = this.Storage.getStorage('wps_user')
    },
    papers(istrue) {
      this.radioOptions = JSON.parse(istrue)
      this.radio = this.radioOptions.id
      if (this.papersId) {
        this.setNetbanCookie(this.papersId)
      } else {
        this.examSelect = true
      }
    },

    examing(id, istrue, title) {
      console.log(111)
      this.isappointment = false
      if (this.$store.state.wps_user.mobile) {
        if (id == 1) {
          if (!this.papersId) {
            this.toForm.id = ''
          } else {
            this.toForm.id = this.papersId
          }
        } else {
          this.toForm.id = id
        }
        this.toForm.istrue = istrue
        this.cookieId = id
        this.toForm.title = title
        let postData = {
          good_id: 1,
          paper_id: this.toForm.id,
        }
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', postData)
          .then(data => {
            this.token = data.data.token
            this.isrepair = data.data.isrepair
            if (data.data.code == 403) {
              _this.$message.error(data.data.msg)
              return
            }
            if (data.data.code == 200) {
              //未支付
              _this.dialogVisible = true
            } else {
              _this.getRepair()
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('您还未登录，请先登录！')
      }
    },
    setNetbanCookie(id) {
      _this.$http
        .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
        .then(data => {
          _this.token = data.data.token
          if (_this.isappointment) {
            _this.attimeShow = true
            return
          }
          if (id) {
            console.log(id)
            window.open(
              'http://netban.cn/api/user/synwp/' + _this.token + '?eid=' + id
            )
          } else {
            window.open(
              'http://netban.cn/api/user/synwp/' +
                _this.token +
                '?eid=' +
                _this.cookieId
            )
          }
        })
    },
    toRedeem() {
      //兑换码
      _this.loading = true
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'exchange', {
          good_id: 1,
          numbers: this.redeemData,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          _this.loading = false
          if (data.data.code == 403 || data.data.code == 500) {
            _this.$message.error(data.data.msg)
            return
          }
          _this.redeem_number = data.data.data
          if (_this.redeem_number) {
            window.location.href =
              'http://pay.netban.cn/pay/' + this.redeem_number //跳转至支付页面
          }
          // _this.getRepair()
          _this.dialogVisible = false
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          _this.loading = false
          console.log(err)
        })
    },
    toPay() {
      //去支付
      this.toExam()
    },
    toExam() {
      this.cookieId = this.toForm.id
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'order', {
          good_id: 1,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          if (data.data.code == 200) {
            //未付款
            _this.order_number = data.data.data
            window.location.href =
              'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    toRZ() {
      let _this = this
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http
            .post(process.env.VUE_APP_URL + 'verify', this.form)
            .then(data => {
              _this.loading = true
              if (data.data.code == 403) {
                _this.$message.error(data.data.msg)
                _this.loading = false
                return
              }
              _this.loading = false
              _this.showInfo = false
              _this.obj.name = _this.form.name
              _this.obj.idcard = _this.form.idcard
              _this.obj.is_verfy = 1
              _this.$store.commit('setUser', _this.obj)
              _this.$message.success('认证成功！去参与测评吧')
              // _this.getRepair()
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              _this.loading = false
              console.log(err)
            })
        }
      })
    },
    warningToRZ() {
      this.showInfo = true
      this.warningDialog = false
    },
    isSelectSure() {
      let postData = {
        papers: this.radio,
      }
      _this.$http
        .post(process.env.VUE_APP_URL + 'examSelect', postData)
        .then(data => {
          if (data.data.code == 200) {
            this.setNetbanCookie(this.radio)
            this.toForm.id = this.radio
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    appshow() {
      this.isappointment = true
      if (this.$store.state.wps_user.mobile) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            this.token = data.data.token
            if (data.data.code == 403) {
              _this.$message.error(data.data.msg)
              return
            }
            if (data.data.code == 200) {
              _this.dialogVisible = true
            } else {
              _this.setNetbanCookie()
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('您还未登录，请先登录！')
      }
    },
    appointment() {
      if (this.attime != '') {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$http
          .post('http://api.wangpeishi.org.cn/api/apply', {
            date: _this.attime,
          })
          .then(res => {
            setTimeout(() => {
              loading.close()
              if (res.data.code == 200) {
                _this.$message.success('预约成功，请考生按时参加考试！')
                _this.attimeShow = false
              } else {
                _this.$message.error(res.data.msg)
                _this.attimeShow = false
              }
            }, 2000)
          })
          .catch(err => {
            setTimeout(() => {
              loading.close()
              _this.$message.error('网络错误，请重试')
              console.log(err)
            }, 2000)
          })
      } else {
        _this.$message.error('请选择预约时间')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.part {
  max-width: 1200px;
  margin: 36px auto 67px;
  padding: 40px 0px;
  // text-align: center;
  background: #ffffff;
  box-shadow: 0px 2px 21px 0px rgba(134, 99, 69, 0.14);
  border-radius: 8px;
  .notice {
    max-width: 1150px;
    background: #f8f9fe;
    border-radius: 6px;
    margin: 0px auto;
    padding: 20px 0px;
    .notice_item {
      max-width: 700px;
      margin: 0px auto;
      img {
        width: 260px;
        height: 210px;
        margin-left: 60px;
      }
    }
    .qrtip {
      float: left;
      text-align: left;
      margin-top: 21px;
      p {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
      .txt {
        font-size: 24px;
        color: #333333;
        line-height: 60px;
      }
      .exam {
        width: 180px;
        height: 40px;
        background-color: #960f23 !important;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 11px;
      }
    }
  }
  .entrance {
    max-width: 1200px;
    padding: 40px 62px 0px;
    text-align: left;
    .br {
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      margin-top: 51px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      width: 800px;
      padding-left: 25px;
      text-indent: -37px;
    }
    .title {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      margin-bottom: 14px;
    }
    .exam {
      float: right;
      width: 180px;
      height: 40px;
      background-color: #960f23 !important;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      position: relative;
      transform: translateY(-260%);
    }
  }
  .tip {
    height: 210px;
    max-width: 1150px;
    margin: 0px auto;
    background: #fffbf7;
    .content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
      color: #666666;
      line-height: 24px;
      margin-bottom: 17px;
    }
    .tip-item {
      max-width: 1000px;
      margin: auto;
      text-align: left;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .indent {
      padding-left: 25px;
      text-indent: -35px;
    }
  }
  .el-dialog {
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background: #960f23;
      width: 100px;
      margin-top: 50px;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 6px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
  .dialog_yy {
    /deep/.el-dialog__header {
      font-size: 30px;
      font-weight: bold;
      color: #434346;
      text-align: left;
      border-bottom: 1px solid #e8e8e8;
    }
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
      font-size: 16px;
      color: #333333;
      line-height: 30px;
      span {
        color: #666666;
      }
    }
    /deep/.el-input.el-input--suffix {
      margin-top: 30px;
    }
    .el-button--danger {
      margin-top: 35px;
    }
  }
  /deep/.el-form-item__label {
    line-height: 60px;
  }
  .warnRed {
    color: red;
    padding-left: 100px;
    font-size: 13px;
  }
  .payStyle {
    div {
      font-size: 15px;
      margin: 10px;
    }
    span {
      font-size: 16px;
      color: #960f23;
      padding-right: 10px;
    }
    .el-button {
      margin-left: 205px;
    }
  }
}
</style>
